<template>
    <form @submit.stop.prevent="onSubmit" :class="formClass" :id="$attrs.id">
        <template v-for="(group, gi) in form.groups">
            <div class="form-group" v-if="group.is() === 'InputGroup'" :key="gi" :class="group.classInputGroup">
                <div class="row">
                    <div class="col-12">
                        <!-- Заголовки группы полей -->
                        <h5 class="title font-weight-bold text-center" v-html="group.title"/>
                        <div class="subtitle text-center" v-html="group.subtitle"/>
                    </div>
                    <div v-for="(input, index) in group.inputs" :key="index + gi"
                         :class="[input.col,'col-12 field', {'field--error': $v[input.name].$error}]"
                    >
                        <!-- Инпуты -->
                        <template v-if="input.is() === 'Input' || input.is() === 'InputFloatLabel'">

                            <label v-html="input.text"
                                   :class="input.is() === 'InputFloatLabel'? 'floating' : ''"
                            />
                            <input class="form-control"
                                   :type="input.type"
                                   :placeholder="input.placeholder"
                                   v-model="$data[input.name]"
                                   v-mask="input.mask"
                                   @input="oninput(input, $event, $event.target.value)"
                            />
                            <template v-if="$v[input.name].required === false">
                                <div class="error">* Обязательное поле</div>
                            </template>
                            <template v-else-if="$v[input.name].email  === false">
                                <div class="error">* Укажите корректный e-mail</div>
                            </template>
                            <template v-else-if="$v[input.name].minLength  === false">
                                <div class="error">* Ошибка в заполнении данных</div>
                            </template>
                            <template v-else-if="$v[input.name].maxLength  === false">
                                <div class="error">* Ошибка в заполнении данных</div>
                            </template>
                            <template v-else-if="$v[input.name].numeric  === false">
                                <div class="error">* Вводите цифры</div>
                            </template>

                        </template>
                        <!-- Чекбоксы -->
                        <template v-else-if="input.is() === 'Checkbox'">

                            <b-form-checkbox
                                    v-for="(option, index) in convertCheckboxToOptions(input)"
                                    v-model="$data[input.name]"
                                    :key="index"
                                    :name="input.name"
                                    :value="option.value"
                                    @change="$v[input.name].$touch()"
                            >
                                <span v-html="option.text"/>
                            </b-form-checkbox>

                            <template v-if="$v[input.name].$error === true">
                                <div class="error d-block" style="opacity: 1">* Обязательное поле</div>
                            </template>

                        </template>
                        <!-- Меню опций -->
                        <template v-else-if="input.is() === 'Select'">

                            <label v-html="input.text"/>
                            <v-select :options="convertSelectToOptions(input)"
                                      v-model="$data[input.name]"
                                      @input="onChangeOption(input, $event)"
                            />
                            <template v-if="$v[input.name].$model === null">
                                <div class="error d-block" style="opacity: 1">* Обязательное поле</div>
                            </template>

                        </template>

                        <!-- Сноска под полем ввода -->
                        <div class="color-gray text-size-ls" v-html="input.footnote"/>
                    </div>
                    <div class="col-12">
                        <!-- Сноска под группой полей -->
                        <div class="text-center" v-html="group.footnote"/>
                    </div>
                </div>
            </div>
        </template>
        <u-button v-bind="submitButton" :disabled="submitStatus === 'PENDING'"/>
        <div class="col-12">
            <p class="text-size-sm text-center color-site mt-3" v-if="submitStatus === 'ERROR'">
                Пожалуйста, заполните форму правильно.
            </p>
            <p class="text-size-sm text-center color-site mt-3" v-if="submitStatus === 'PENDING'">
                Обработка запроса...
            </p>
        </div>
    </form>
</template>

<script>
    import {Form} from "../../classes/form/";
    import {Checkbox, Select, InputFloatLabel} from "../../classes/form/input";

    export default {
        name: "BasicForm",
        components: {
            UButton: () => import(/* webpackChunkName: "u-button" */'../UButton'),
        },
        data() {
            const data = {};

            /**
             * Перебираем  массив групп инпутов this.form.groups,
             * и записываем в this.$data ключ = значение,
             * где ключ = [group.inputs[j].name] (имя инпута),
             * а значение = group.inputs[j].value (значение инпута)
             */
            for (let i = 0; i < this.form.groups.length; i++) {
                const group = this.form.groups[i];

                for (let j = 0; j < group.inputs.length; j++) {
                    /**
                     * Проверка на принадлежность классу
                     */
                    if (group.inputs[j] instanceof Checkbox) {
                        /**
                         * Если не существует такого поля,
                         * то создаем новое, со значением равным пустому массиву
                         * (чтобы записывать выбранные чекбосы в массив)
                         */
                        if (typeof data[group.inputs[j].name] === 'undefined') {
                            data[group.inputs[j].name] = [];
                        }
                    } else if (group.inputs[j] instanceof Select) {
                        /**
                         * Создаем поля и записываем в data
                         */
                        data[group.inputs[j].name] = group.inputs[j].options[0];
                    } else {
                        /**
                         * Создаем поля и записываем в data
                         */
                        data[group.inputs[j].name] = group.inputs[j].value;
                    }
                }
            }

            return {
                ...data,
                submitStatus: null,
                additionalGroupsHidden: true,

            };
        },
        validations() {
            return this.form.getValidatorParameters()
        },
        props: {
            formClass: {
                type: String,
                default: 'form-default',
            },
            form: {
                type: Form,
                default() {
                    return new Form;
                }
            },
            submitButton: {
                type: Object,
                default() {
                    return {
                        variant: 'u-button',
                        text: 'Отправить',
                        size: 'medium',
                        classButton: 'd-block mx-auto long',
                        type: 'submit',
                    };
                }
            },
        },
        methods: {
            /**
             * @param input
             * @param event
             * @param value
             */
            oninput(input, event, value) {
                this.$v[input.name].$touch();

                if (input instanceof InputFloatLabel) {
                    const el = event.target;
                    const label = el.previousElementSibling;

                    if (value.length > 0) {
                        label.classList.add('float');
                    } else {
                        label.classList.remove('float');
                    }
                }
            },
            /**
             * @param input
             * @param value
             *
             * Событие при изменении select,
             * Навешиваем $v.$touch() на выбранный инпут;
             * Если выбор кол-ва учредителей, то меняем набор групп инпутов
             */
            onChangeOption(input, value) {
                this.$v[input.name].$touch();

                if (input.name === 'registration_ooo_number_of_founders') {
                    if (value === '2') {

                        this.showAdditionalGroups();

                        /**
                         * Если value === '2', то добавляем группу полей под второго учреда
                         */
                        this.$data.tn = 4;

                        // console.log(this.$data.tn);
                    } else if (value === '1') {

                        this.hideAdditionalGroups();

                        /**
                         * Если value === '1', то удаляем созданную группу полей под второго учреда
                         */
                        this.$data.tn = 3;

                        // console.log(this.$data.tn);
                    }
                }
            },
            /**
             * @param input
             */
            convertCheckboxToOptions(input) {
                return [
                    {
                        text: input.text,
                        value: input.value
                    }
                ]
            },
            /**
             * @param input
             */
            convertSelectToOptions(input) {
                return input.options;
            },
            /**
             * Send form
             * Не нужно вызывать
             * event.preventDefault();
             * event.stopPropagation(); т.к. методы объявлен в форме
             */
            onSubmit() {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    /** если есть ошибка **/
                    this.submitStatus = 'ERROR';
                } else {
                    this.submitStatus = 'PENDING';
                    /** если ошибки нет **/

                    this.form.send(this.$data)
                        .then((result) => {
                            //     console.log(result);
                            // console.log(result.mailSend);
                                this.onReset();

                                if (this.form.name === 'Выбрать форму бизнеса') {
                                    this.$scrollTo('#scroll-from-lead-form', 1000);
                                }else if (result.mailSend === true){
                                    this.$root.$emit('bv::show::modal', 'success-modal');
                                } else {
                                    /**
                                     * порождает событие вызова модального окна
                                     */
                                    this.$root.$emit('bv::show::modal', 'success-modal');
                                }
                            }
                        )
                        .catch((error) => {
                            console.log(this.form.errors);

                            /**
                             * порождает событие вызова модального окна
                             */
                            this.$root.$emit('bv::show::modal', 'failed-modal');

                            if (error.response) {
                                // Request made and server responded
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                            }
                        });

                    this.submitStatus = null;
                }
            },
            onReset() {
                this.$v.$reset();

                for (let i = 0; i < this.form.groups.length; i++) {
                    const group = this.form.groups[i];

                    for (let j = 0; j < group.inputs.length; j++) {
                        if (group.inputs[j] instanceof Checkbox) {
                            this.$data[group.inputs[j].name] = [];
                        } else if (group.inputs[j] instanceof Select) {
                            this.$data[group.inputs[j].name] = group.inputs[j].options[0];
                        } else {
                            const tn = this.$data.tn;
                            const type = this.$data.type;

                            this.$data[group.inputs[j].name] = '';
                            this.$data.tn = tn;
                            this.$data.type = type;
                        }
                    }
                }
            },
            /**
             * Показываем скрытые дополнительные группы
             */
            showAdditionalGroups() {
                const additionalGroup = document.querySelectorAll('.additional-group');

                this.additionalGroupsHidden = false;

                [...additionalGroup].map((el) => {
                    el.classList.add('show');
                })
            },
            /**
             * Скрываем открытые дополнительные группы
             */
            hideAdditionalGroups() {
                const additionalGroup = document.querySelectorAll('.additional-group');

                this.additionalGroupsHidden = true;

                [...additionalGroup].map((el) => {
                    el.classList.remove('show');
                })
            },
        },

    }
</script>
